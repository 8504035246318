import React, { useState, useEffect } from 'react';
import axios from 'axios';

function AppointmentsPage() {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    // Fetch the appointments from the backend
    axios.get('https://backend.emraisystem.com/appointments')
      .then(response => {
        setAppointments(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the appointments!", error);
      });
  }, []);

  return (
    <div>
      <h1>Appointments Page</h1>
      <ul>
        {appointments.map(appointment => (
          <li key={appointment.id}>
            Appointment ID: {appointment.id}, Patient ID: {appointment.patient_id}, 
            Doctor ID: {appointment.doctor_id}, Practice Location ID: {appointment.practice_location_id}, 
            Date: {appointment.appointment_date}, Notes: {appointment.notes}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AppointmentsPage;
