// src/pages/WelcomePage.js

import React from 'react';
import { Link } from 'react-router-dom';

function WelcomePage() {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Welcome to the EMR System</h1>
      <p>This is your Electronic Medical Record system.</p>
      <div>
        <Link to="/patients">
          <button style={{ margin: '10px', padding: '10px 20px' }}>View Patients</button>
        </Link>
        <Link to="/doctors">
          <button style={{ margin: '10px', padding: '10px 20px' }}>View Doctors</button>
        </Link>
        <Link to="/appointments">
          <button style={{ margin: '10px', padding: '10px 20px' }}>View Appointments</button>
        </Link>
      </div>
    </div>
  );
}

export default WelcomePage;
