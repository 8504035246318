import React, { useEffect, useState } from 'react';
import { getPatients } from '../services/patientService';

function PatientsPage() {
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    loadPatients();
  }, []);

  const loadPatients = async () => {
    const response = await getPatients();
    setPatients(response.data);
  };

  return (
    <div>
      <h1>Patients</h1>
      <ul>
        {patients.map(patient => (
          <li key={patient.id}>
            {patient.name} - {patient.dob} - {patient.phone}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PatientsPage;
