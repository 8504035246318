import React, { useState, useEffect } from 'react';
import axios from 'axios';

function DoctorsPage() {
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    axios.get('https://backend.emraisystem.com/doctors')
      .then(response => {
        setDoctors(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the doctors!", error);
      });
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <h1>Doctors List</h1>
      {doctors.length > 0 ? (
        <ul>
          {doctors.map(doctor => (
            <li key={doctor.id}>
              <strong>{doctor.name}</strong><br />
              Phone: {doctor.phone}<br />
              Specialties: {doctor.specialties.join(', ')}
            </li>
          ))}
        </ul>
      ) : (
        <p>No doctors available.</p>
      )}
    </div>
  );
}

export default DoctorsPage;
